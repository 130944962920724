import React, { useRef  	 } from "react";
import {
  Box,
  Container,
  Flex,
  VStack,
  Heading,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useBreakpointValue,
} from "@chakra-ui/react";

const faqs = [
  {
    question: "Bu program bir aplikasyon mu? ",
    answer:
      "Hayır, program bir öğrenme platformunda, Telegram'da ve Zoom'da konuları çalışma ve pratik yapma üzerine kurulu.",
  },
  {
    question:
      " Bazen işlerim çok yoğun oluyor. Her haftada bir modül bitiremezsem ne olur? ",
    answer:
      "Önceden belirlenmiş tempoyu kendi ihtiyaçlarınıza göre esnek hale getirebilirsiniz. Geçmiş modüllere erişim kapanmıyor ve Telegram aktiviteleri için son tarih yok. Bu nedenle, iş temponuzun yoğun olduğu haftalardaki aktiviteleri bir sonraki haftada yakalayabilirsiniz.",
  },
  {
    question: "Bu platformda TOEIC, IELTS gibi testlere hazırlanabilir miyim? ",
    answer:
      "Evet, ancak her testin kendine özel bir içeriği olduğu için programa başladığınızda destek ekibimize hangi teste hazırlandığınızı belirtmeniz gerekiyor. Biz size uygun kaynakları sizinle paylaşıyoruz ve teste hazırlanmanıza yardımcı oluyoruz. Ancak FluentChatter spesifik olarak test hazırlığına değil, İngilizce'yi akıcı konuşabilme ve anlayabilme becerilerine yöneliktir.",
  },
  {
    question: "Canlı dersler var mı? ",
    answer:
      "Evet! Her ay iki kere Zoom'da canlı olarak açıklamalı egzersizler yapıyoruz ve kaçırırsanız Replay olarak kaydedilmiş oturumu seyredebiliyorsunuz. Ayrıca kayıtta seçtiğiniz aboneliğe göre (Basic/Super/Premium) belli sayıda birebir seanslar ile koçlarla özel ihtiyaçlarınıza göre çalısabilirsiniz. Daha fazla seansa ihtiyaciniz varsa yeni seans satın alarak dilediğiniz sayıda birebir ders yapabilirsiniz.",
  },
  {
    question: "Hiç İngilizce bilmiyorum. Bu programı kullanabilir miyim?",
    answer:
      "Elbette. Türkçe açıklamalar ve Türkçe bilen bir ekip olduğu için takıldığın yerlerde anadilinde destek alabilirsin.",
  },
  {
    question: "Bu programın diğer programlara göre avantajı ne?",
    answer:
      "Maksimum pratik yapma imkanı, uygun ve erişilebilir fiyat, günlük hayatın yoğun temposuna uyumlu ve sürekli destek gördüğünüz bir program. ",
  },
  {
    question:
      "Konuları çalışmak ve egzersizler yapmak disinda haftada kaç saat aktif pratik yapabilirim?",
    answer:

      "Telegram grubunda sürekli pratik yapabilir, düzenli olarak aldığınız geri bildirimlerle seviyenizi geliştirebilirsiniz. Zoom seansları aracılığıyla haftada üç saat konuşma pratiği yapabilir, dilerseniz bu süreyi artırabilirsiniz.",
  },
];

const FAQ: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const refs = useRef<{
    [key: string]: HTMLDivElement | HTMLButtonElement | null;
  }>({});

  const SCROLL_OFFSET = 80; // Adjust this value based on your header height

  const scrollToQuestion = (questionId: string) => {
    const element = refs.current[questionId];
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - SCROLL_OFFSET;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const createQuestionId = (index: number) => `question-${index}`;

  return (
    <Box>
      <Box bg="linear-gradient(to right, purple.500, blue.500)" py={16} mb={8}>
        <Container maxW="container.xl">
          <Heading color="white" size="2xl" textAlign="center">
            Sıkça Sorulan Sorular
          </Heading>
        </Container>
      </Box>
      <Container maxW="container.xl">
        <Flex direction={{ base: "column", md: "row" }}>
          {!isMobile && (
            <Box width="250px" flexShrink={0} mr={8}>
              <VStack
                align="stretch"
                position="sticky"
                top="20px"
                maxHeight="calc(100vh - 100px)"
                overflowY="auto"
              >
                <Heading as="h3" size="md" mb={2}>
                  İçindekiler
                </Heading>
                {faqs.map((faq, index) => (
                  <Button
                    key={index}
                    onClick={() => scrollToQuestion(createQuestionId(index))}
                    variant="ghost"
                    justifyContent="flex-start"
                    fontWeight="normal"
                    color="blue.500"
                    fontSize="sm"
                    whiteSpace="normal"
                    textAlign="left"
                    height="auto"
                    py={1}
                  >
                    {faq.question}
                  </Button>
                ))}
              </VStack>
            </Box>
          )}
          <Box flex={1}>
            <Accordion
              defaultIndex={faqs.map((_, index) => index)}
              allowMultiple
            >
              {faqs.map((faq, index) => (
                <AccordionItem key={index}>
                  <h3>
                    <AccordionButton
                      ref={(el) => (refs.current[createQuestionId(index)] = el)}
                    >
                      <Box flex="1" textAlign="left" fontWeight="bold">
                        {faq.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h3>
                  <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default FAQ;
