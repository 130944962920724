import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  HStack,
} from "@chakra-ui/react";

import { faqs } from "@/constants/dataPublicPages";
import ButtonLink from "../common/ButtonLink";
 

const FAQSection: React.FC = () => {
  


  return (
    <Box py={16} bg="pink.100">
      <Container maxW="container.xl">
        <Heading
          as="h2"
          size="2xl"
          mb={8}
          textAlign="center"
          colorScheme="pink"
          color="pink.500"
        >
          Sıkça Sorulan Sorular
        </Heading>
        <Flex
          data-testid="faq-container"
          direction={{ base: "column", md: "row" }}
          align="start"
          justify="space-between"
        >
          <Box
            width={{ base: "100%", md: "60%" }}
            pr={{ base: 0, md: 8 }}
            data-testid="faq-left-section"
          >
            <Accordion allowToggle>
              {faqs.map((faq, index) => (
                <AccordionItem key={index}>
                  <h3>
                    <AccordionButton _expanded={{ bg: "pink.400" }}>
                      <Box flex="1" textAlign="left" fontWeight="medium">
                        {faq.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h3>
                  <AccordionPanel pb={4} bg="pink.200">
                    <Text dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
            <HStack mt={8} spacing={4}>
              <ButtonLink
                linkTo="/faq"
                text="Tüm Soruları Oku"
                variant="outline"
              />
              <ButtonLink

                linkTo="/contact"
                text="Bize Ulaşın"
                variant="solid"
              />
            </HStack>

          </Box>
          <Box
            width={{ base: "100%", md: "40%" }}
            mt={{ base: 8, md: 0 }}
            data-testid="faq-right-section"
          >
            <Image
              src="/assets/studentsuccess/Veronika.png.webp"
              alt="Happy student"
              borderRadius="full"
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default FAQSection;
