import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    VStack,
  } from '@chakra-ui/react';
import ScenarioCard from './ScenarioCard';
import { learnerScenerios as scenarios } from '@/constants/dataPublicPages';

  const EnglishLevelSection: React.FC = () => {
    
    return (
      <Box py={16} bg="pink.200">
        <Container maxW="container.xl">
          <VStack spacing={8}>
            <Heading as="h2" size="xl" textAlign="center">
            Şu anki İngilizcen nasıl?  <Text as="span" color="pink.500">Muhtemelen Şöyle...</Text>
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} w="full">
              {scenarios.map((scenario, index) => (
                <ScenarioCard key={index} {...scenario} />
              ))}
            </SimpleGrid>
            <Heading as="h3" size="md" textAlign="center">
            Neden? Çünkü öğrenirken <Text as="span" color="pink.500">pratik yapma imkanın yok.</Text>
            Evinin rahatından, çalışma tempona uydurabileceğin şekilde ve diğer metotlara göre yaklaşık <Text as="span" color="green.600">6 kat daha fazla pratik yapma imkanı</Text> sağlayan eğlenceli bir ortamda İngilizce öğrenmeye ne dersin? 
            </Heading>
          </VStack>
        </Container>
      </Box>
    );
  };
  
  export default EnglishLevelSection;