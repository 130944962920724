export const learnerScenerios = [
  {
    emoji: "🏫",
    description:
      "Okulda öğrendiğin biraz gramer/kelime bilgin var ama hiç işe yaramıyor",
  },
  {
    emoji: "📲",
    description:
      "Bir aplikasyon ile ilerletmeye çalışıyorsun ama yeterli olmuyor. ",
  },
  {
    emoji: "👨‍🏫",
    description: "Haftada 1-2 kere özel ders alıyorsun ama çok pahalı.",
  },
  {
    emoji: "🤔",
    description: "Ve hala Konuşulanları anlamakta zorlanıyorsun.",
  },
  {
    emoji: "😰",
    description: "İngilizce konuşurken hata yapmaktan korkuyorsun.",
  },
  {
    emoji: "🤷‍♀️",
    description:
      "En önemlisi de konuşurken sık sık takılıyor ve devam edemiyorsun.",
  },
];

export const faqs = [
  {
    question: "Bu program bir aplikasyon mu?",
    answer:
      "Hayır, program bir öğrenme platformunda, Telegram’da ve Zoom’da konuları çalışma ve pratik yapma üzerine kurulu.",
  },
  {
    question:
      "Bazen işlerim çok yoğun oluyor. Her haftada bir modül bitiremezsem ne olur?",
    answer:
      "Önceden belirlenmiş tempoyu kendi ihtiyaçlarınıza göre esnek hale getirebilirsiniz. Geçmiş modüllere erişim kapanmıyor ve Telegram aktiviteleri için “son tarih” yok. Bu nedenle, iş temponuzun yoğun olduğu haftalardaki aktiviteleri bir sonraki haftada yakalayabilirsiniz.",
  },
  {
    question: "Bu platformda TOEIC, IELTS gibi testlere hazırlanabilir miyim?",
    answer:
      "Evet, ancak her testin kendine özel bir içeriği olduğu için programa başladığınızda destek ekibimize hangi teste hazırlandığınızı belirtmeniz gerekiyor. Biz size uygun kaynakları sizinle paylaşıyoruz ve teste hazırlanmanıza yardımcı oluyoruz. Ancak FluentChatter spesifik olarak test hazırlığına değil, İngilizce’yi akıcı konuşabilme ve anlayabilme becerilerine yöneliktir.",
  },
  {
    question: "Canlı dersler var mı?",
    answer:
      "Evet! Her ay iki kere Zoom’da canlı olarak açıklamalı egzersizler yapıyoruz ve kaçırırsanız “Replay” olarak kaydedilmiş oturumu seyredebiliyorsunuz. Ayrıca kayıtta seçtiğiniz aboneliğe göre (Basic/Super/Premium) belli sayıda birebir seanslar ile koçlarla özel ihtiyaçlarınıza göre çalısabilirsiniz. Daha fazla seansa ihtiyaciniz varsa yeni seans satın alarak dilediğiniz sayıda birebir ders yapabilirsiniz.",
  },
  {
    question: "Hiç İngilizce bilmiyorum. Bu programı kullanabilir miyim?",
    answer:
      "Elbette. Türkçe açıklamalar ve Türkçe bilen bir ekip olduğu için takıldığın yerlerde anadilinde destek alabilirsin.",
  },
  {
    question: "Bu programın diğer programlara göre avantajı ne?",
    answer:
      "Maksimum pratik yapma imkanı, uygun ve erişilebilir fiyat, günlük hayatın yoğun temposuna uyumlu ve sürekli destek gördüğünüz bir program. ",
  },
  {
    question:
      "Konulari çalismak ve egzersizler yapmak disinda haftada kaç saat aktif pratik yapabilirim?",
    answer:
      "Telegram grubunda sürekli pratik yapabilir, düzenli olarak aldığınız geri bildirimlerle seviyenizi geliştirebilirsiniz. Zoom seansları aracılığıyla haftada üç saat konuşma pratiği yapabilir, dilerseniz bu süreyi artırabilirsiniz.",
  },
  {
    question:
      "Koçlarınız native speaker değiller. Bunun bir dezavantajı varmı?",
    answer:
      "Hayır. Günümüzde İngilizce konuşan dünya nüfusunun sadece %5'inin anadili İngilizce. Koçlarımızın hepsi alanında uzman ve deneyimli, native-level seviyesinde İngilizce konuşan profesyonellerdir. Dil öğretme becerisi native speaker olmaktan bağımsızdır.",
  },
];

export const heroData = {
  headingText: "İngilizce öğrenmenin yeri burası.",
  subheadingText_mobile: "Çatır çatır İngilizce konuşturan online kurs.",
  subheadingText_desktop:
    "Çatır çatır İngilizce konuşturan, Türkçe destekli ilk online İngilizce kursu.",
  button_joinus_text: "Katıl Bize !",
};

export const heroAboutData = {
  headingText:
    "Fluent Chatter.Size Çatır çatır İngilizce konuşmayı öğreten çevrimiçi okul.",
  subheadingText_mobile: "Ingilizce öğreniminde devrim yaratıyoruz.",
  subheadingText_desktop:
    "Profesyonel öğretmenlerin önderlik ettiği canlı çevrimiçi etkinlikler  aracılığıyla dil öğreniminde devrim yaratıyoruz.",
  button_joinus_text: "Katıl Bize !",
};

export const heroCreators = {
  headingText: "Content Creatorlar , Birlikte Kazanalim ",
  subheadingText_mobile: "Tum sosyal medya icerik ureticilerine kapimiz acik.",
  subheadingText_desktop:
    "Tum sosyal medya icerik ureticilerine kapimiz acik.Iletisime gecin birlikte kazanalim.",
  button_joinus_text: "Ulas Bize !",
};

export const heroHowItWorksData = {
  headingText: "Fluent Chatter sistemi sizin için nasıl çalışır?",
  subheadingText_mobile: "Sistemin Nasıl Çalıştığını İnceleyin.",
  subheadingText_desktop:
    "Derste neler olduğunu, nasıl başlayacağınızı ve Fluent Chatter'ın güvenle konuşmanıza nasıl yardımcı olacağını okuyun.",
  button_joinus_text: "Katıl Bize !",
};

export const howitworks_data = {
  headertext: "Fluent Chatter ile her şey değişecek!",
  aspects: [
    {
      title: "Kurs Platformu",
      description:
        "A super modern and intuitive online space where you will discover 26 thematic modules, one module per week. In each module, you will find video lessons, interactive exercises and PDF files to review and practice throughout the week. Everything you need to learn English in a practical way!",
      imageSrc: "/assets/howitworks/platform.png",
    },
    {
      title: "Pratiğini nasıl yapıyorsun?",
      description:
        "Dil öğrenmenin kalbi PRATİK! Platform dışında Telegram Gruplarında ve Zoom üzerinde yapılan canlı Speaking Groups seansları ile diğer platformlara göre çok daha fazla pratik yapma imkânın oluyor.",
      extended: `Platformda öğrendiğiniz dil becerilerini uygulamak için sizin seviyeniz ile 
aynı seviyedeki diğer kullanıcılarla iletişime dayalı aktiviteler var! Bu 
aktiviteler sizin “output” kaynağınız, yani  edindiğiniz bilgileri dile dökme 
fırsatı.  Programı diğerlerinden farklı kılan en can alıcı özelliği olup size programdaki diğer kişilerle birlikte olma ve grubunuzun Koçu ile fikir alışverişinde bulunma imkani verir. Koç, uygulamanızda 
size rehberlik eder ve size özel geri bildirim verir. Haftanın sonunda ise 
grubun koçu genel grup geri bildirimi ile konuya ait püf noktalari hatırlatır. 
Telegram grubunuzda pratik aktivitelerinizi yapmanın yanı sıra 
grubunuzdaki insanlarla sohbet edip bir topluluk oluşturabilirsiniz.`,
      imageSrc: "/assets/howitworks/zoommeet.webp",
    },
    {
      title: "Speaking Groups seansları",
      description: "Haftada üç kere Zoom'da -Speaking Groups Seanslar",
      list: [
        "Modüllerde öğrenilenleri pratik yapmanın yanı sıra günlük doğal konuşma gibi spontane iletişimi amaçlayan ve akıcılığını geliştireceğin, önceden hazırlanmış ve platformdan PDF olarak indirebileceğin aktiviteler ile koç eşliğinde 1'er saatlik seanslar.",
        "Bu seansların saatleri sabittir ve öğle saatlerinde ya da akşam saatlerindedir. Amaç konuşma aktiviteleri ile diğer öğrencilerle fikir alışverişinde bulunmak ve akıcılığınızı pratik etmek!",
        "Seans saatleri ile günlük programanız çakışabilir. Bu durumda bireysel veya küçük gruplar oluşturarak hafta içi veya hafta sonu seans rezervasyonu yapabilirsiniz. Ücretsiz seanslar sınırlı sayıdadır ancak ek ödeme ile dilediğiniz kadar seans rezerve edebilirsiniz.",
      ],
      imageSrc: "/assets/howitworks/telegrams.jpg",
    },
  ],
  stepper_steps: [
    {
      title: "Seviyeni Test Et",
      description:
        "Kayıt olmadan önce yapacağın Seviye Tespit Sınavında aldığın skora göre 6 ay süren FluentChatter Academy ya da FluentChatter Club programlarından birine başlıyorsun.",
      tooltip:
        "Kayıt olmadan önce yapacağın Seviye Tespit Sınavında aldığın skora göre 6 ay süren FluentChatter Academy ya da FluentChatter Club programlarından birine başlıyorsun.",
    },
    {
      title: "Online Kursa Başla",
      description:
        "Uygulama tamamen online çünkü mekan ve zaman sınırı olmadan herkes İngilizce öğrenebilsin istiyoruz. İngilizce'yi pratik bir şekilde öğrenmek için ihtiyacınız olan her şeyi bu programda bulacaksınız!",
      tooltip:
        "Katıldığın programa göre haftada bir modül veya iki haftada bir modül öğreniyorsun.",
    },
    {
      title: "Çalışma temposu",
      description:
        "Fluent Chatter Academy programında 26 modül (26 hafta), Fluent Chatter Club programında 6 modül (12 hafta) mevcut olup, süper modern online ortamda iletişimi ön planda tutan bir program ile İngilizce ögreniyorsun.",
      tooltip:
        "Katıldığın programa göre haftada bir modül veya iki haftada bir modül öğreniyorsun.",
    },
    {
      title: "Gruplara Katıl Pratik Yap",
      description:
        "Pratik yapmak için Telegram Grup Aktivitelerine ve Zoom Speaking Groups seanslarına katılıyorsun.",
      tooltip:
        "Pratik yapmak için Telegram Grup Aktivitelerine ve Zoom Speaking Groups seanslarına katılıyorsun.",
    },
  ],
};

export const OurWay_data = {
  header: "Günlük Yaşam Temalarıyla İngilizce Öğrenin",
  list: [
    `İngilizceyi ders kitabından öğretmiyoruz. Kendi uzman müfredatımızla size yerel biri gibi İngilizce konuşmayı öğretiyoruz.`,
    
    `Koçlarınız motivasyonunuzu korumanıza yardımcı olacak ve
            güvenli ve destekleyici bir ortamda büyüyebilmeniz için size
            bireysel geri bildirimler verecektir.`,
  ],
};

export const courses_data = {
  heading: "Kurslar ve İçerikleri",
  courses: [
    {
      title: "A1-A2 Seviyesi Kursu",
      duration: "26 Hafta",
      modules: "26 Modul",
      ModuleDuration: "Haftada 1 Modul",
      teachers: "Bilingual - Türkçe Konuşan Öğretmenler",
      descriptions: [
        "Konuşma ve anlama başta olmak üzere tüm dil becerileri",
        "Kendi hayatınıza adapte edebileceğiniz esnek bir tempo",
        "Telegramda grup halinde bolca aktivite",
        "Zoom uzerinden Konuşma Pratikleri",

      ],


      cards: [
        {
          imageURL: "/assets/courses/video.png",
          oneLinerText: "Anlatimli Videolar",
        },
        {
          imageURL: "/assets/courses/quiz.png",
          oneLinerText: "İnteraktif Quizler",
        },
        {
          imageURL: "/assets/courses/telegram.png",
          oneLinerText: "Telegram Gruplari",
        },
        {
          imageURL: "/assets/courses/pdf.png",
          oneLinerText: "Pdf Ders Notlari",
        },

        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Zoom Seansları",
        },
        {
          imageURL: "/assets/courses/turkce.png",
          oneLinerText: "Turkce Anlatim",
        },
        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Esnek Tempo",
        },
      ],
    },
    {
      title: "B1-B2 Seviyesi Kursu",
      duration: "12 Hafta",
      modules: "6 Modul",
      ModuleDuration: "2 Haftada 1 Modul",
      teachers: "Temalar ile konuşma pratiği geliştirmeye dayalı",
      descriptions: [
        'Telegramda Konuşma Gruplarıyla akıcılığınızı geliştirin.',
        "Zoom uzerinden Konuşma Pratikleri",
        "Kendi hayatınıza adapte edebileceğiniz esnek bir tempo",
        

      ],
      cards: [
        {
          imageURL: "/assets/courses/subjects.png",
          oneLinerText: "Farkli Temalar",
        },
        {
          imageURL: "/assets/courses/natives.png",
          oneLinerText: "Native Speakers",
        },
        {
          imageURL: "/assets/courses/video.png",
          oneLinerText: "Anlatimli Videolar",
        },

        {
          imageURL: "/assets/courses/quiz.png",
          oneLinerText: "İnteraktif Quizler",
        },
        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Zoom Seansları",
        },
        {
          imageURL: "/assets/courses/pdf.png",
          oneLinerText: "Pdf Ders Notlari",
        },
        {
          imageURL: "/assets/courses/telegram.png",
          oneLinerText: "Telegram Gruplari",
        },
      ],
    },
    {
      title: "Özel Dersler",
      duration: "30-45 dk",
      modules: "1 Modul",
      ModuleDuration: "Kurs Boyunca Rezervasyon",
      teachers: "İsteğe Bağlı Native / Bilingual Öğretmenler",
      descriptions: [
        "Randevu alınarak istenilen tarih ve saatte ders.",

        "Ders ücreti sabit , grup olarak katılın ve ücreti bölüşün",
        "Anadili İngilizce olan uzmanlardan istediğiniz konuda ders alın",


        "İsteğinize göre bir konu üzerinde çalışın ya da sadece konuşma pratiği yapın.",
      ],
      cards: [
        {
          imageURL: "/assets/courses/subjects.png",
          oneLinerText: "Konuyu Siz Seçin",
        },
        {
          imageURL: "/assets/courses/practice.png",
          oneLinerText: "Partik Yapın",
        },
        {
          imageURL: "/assets/courses/flexible.png",
          oneLinerText: "Esnek Randevu",
        },
        {
          imageURL: "/assets/courses/splitbill.png",
          oneLinerText: "Ucreti bölüşün",
        },
        {
          imageURL: "/assets/courses/natives.png",
          oneLinerText: "Native Speakers",
        },
        {
          imageURL: "/assets/courses/zoom.png",
          oneLinerText: "Zoom ile katılın",
        },
      ],
    },
  ],
  packages: [
    {
      title: "A1-A2 Seviyesi",
      bgColor: "pink.200",
      color: "purple.800",
      description:
        "26 modülden oluşan ve her hafta yeni bir modülün başladığı, kişinin kendi hayatına adapte edebileceği esnek bir tempoda konuşma ve anlama başta olmak üzere tüm dil becerilerinin geliştirildiği bir program.",
      features: [
        "Türkçe ve İngilizce konu anlatım videoları,",
        "Interaktif quizler",
        "Pratik PDF ders notları",
        "Telegram grupları Pratik Aktiviteleri-A1/A2",
        "Zoom üzerinde Konuşma Seansları (Speaking Groups) -A1/A2",
      ],
    },
    {
      title: "B1-B2 Seviyesi",
      bgColor: "pink.600",
      color: "gray.300",
      description:
        "6 modülden oluşan ve 2 haftada bir yeni modülün başladığı , her modülde bir temaya odaklanan, kelime dağarcığını geliştirmeye ve akıcı konuşmaya ağırlık veren bir programdır.",
      features: [
        "Her ay farklı bir tema: Seyahat, İş Dünyası, Hobiler, vb..",
        "Her modülde konu içerisinde gramer bilgini de pekiştiren pratiğe dayalı 'task'leer, yani ödevler vardır.",
        "Telegram grupları Pratik Aktiviteleri-B1/B2",
        "Zoom üzerinde Konuşma Seansları (Speaking Groups) -B1/B2",
      ],
    },
    {
      title: " Özel Dersler",
      bgColor: "purple.700",
      color: "white",
      description:
        "Belirleyeceğiniz saatte, seçtiğiniz konu, tema veya alanda birebir veya grup özel dersleri ve canlı dersler.",
      features: [
        "Randevu alınarak istenilen tarih ve saatte ders alın.",
        "Bir gruba katılın ve kurs ücretinizi bölüşün",
        "Anadili İngilizce olan uzmanlardan ders alın",
        "İsteğinize göre bir konu üzerinde çalışın ya da sadece konuşma pratiği yapın.",
      ],
    },
  ],
};

export const sampleClass_data = {
  classes: [
    {
      imageUrl: "/assets/studentsuccess/personal-detail.png.webp",
      title: "Modül 1",
      description:
        "Cümle kurma temel bilgisi ,Soru sorma,Sıfatlarla tasvirler",
    },
    {
      imageUrl: "/assets/studentsuccess/hobbies-and-free-time.png.webp",
      title: "Modül 2",
      description:
        "Rutinlerimizden söz etmek,Geniş zaman ile cümleler",
    },
    {
      imageUrl: "/assets/studentsuccess/the-indefinite-article.png.webp",
      title: "Modül 3",
      description:
        "Bir iş görüşmesinde resmi konuşmayı nasıl kullanacağınızı öğrenin",

    },
    {
      imageUrl: "/assets/studentsuccess/how-much-does-it-cost.png.webp",
      title: "Modül 4",
      description:
        "Yurt dışı seyahatlerinizde en çok kullandığınız ifadeleri öğrenin.",

    },
  ],
};

export const studentStories_data = {
  heading: "Öğrencilerimizin başarı hikayelerinden ilham alın",
  description: `Öğrencilerimizin yabancı dil konusunda nasıl hayat değiştiren adımlar attıklarını dinleyin. Onların hikayelerinin sizi kendi dil özgürlüğünüzü kazanmanız için motivesiyle harekete geçin.`,
  stories: [
    {
      name: "Savannah",
      imageSrc: "/assets/studentsuccess/Savannah.png",
      videoId: 1003996563,
    },
    {
      name: "Jean Marc Lecomte",
      imageSrc: "/assets/testimonialAvatars/jean marc lecomte_photo.jpg",
      description:
        "Kelime haznesini  nasil gelistirdigini ve artik yabanci ulkelerde nasil guvenle akici ingilizce konustugunu anlatıyor!",
      videoId: 1003996563,
    },
    {
      name: "Veronika",
      imageSrc: "/assets/studentsuccess/Veronika.png.webp",
      videoId: 1003996563,
    },
    {
      name: "Olivera",
      imageSrc: "/assets/studentsuccess/Olivera.png.webp",
      description:
        "Sıfır İngilizceden kesintisiz sohbetlere: Olivera, Fluent Chatter'ın Paris'te dil becerilerini nasıl dönüştürdüğünü anlatıyor!",
      videoId: 1003996563,
    },
  ],
};

export const teachers_data = {
  heading: "İngilizce öğretmenlerimizden bazılarıyla tanışın",
  teachers: [
    {
      name: "Seda Buche",
      imageUrl: "/assets/teachers/Seda.png",
      origin: "France",
      teachLanguages: "English",
      speakLanguages: "English, French and Turkish",
    },
    {
      name: "John Doe",
      imageUrl: "/api/placeholder/150/150",
      origin: "Ireland",
      teachLanguages: "English",
      speakLanguages: "English, Gaelic",
    },
    {
      name: "Jane Feather",
      imageUrl: "/api/placeholder/150/150",
      origin: "U.K.",
      teachLanguages: "English",
      speakLanguages: "English, French, and German",
    },
  ],
};

export const testimonials_data = {
  heading: "Öğrencilerimiz Konuşuyor",
  seubheading: "Dünyanın her yerindeki öğrencilerle çalışıyoruz",
  testimonials: [
    {
      testimonialHeading: "",
      testimonialText: `I have been working in the finance department of an international company.
       During my work, I had to attend meetings in English all the time. To improve my spoken and written English on a daily basis, 
       I found Fluent Chatter. 
       I have a better vocabulary and a better knowledge of grammar. 
       Above all, I'm no longer afraid to speak during meetings with foreign colleagues and I have self-confidence when I speak in English.`,
      avatar: {
        name: "Yan Madern",
        title: "Student",
        imgsrc: "/assets/testimonialAvatars/Yan_Madern_photo.jpeg",
      },
    },
    {
      testimonialHeading: "",
      testimonialText: `I was always frustrated when I went to foreign countries or when I met native spoken English people.
       I couldn't chat with them. I have better vocabulary and grammar knowledge since I have studied with FluentChatter. 
      I am more confident because I have improved my English fluency. I am more motivated than ever before to make more progress.`,
      avatar: {
        name: "Jean Marc Lecomte",
        title: "Student",
        imgsrc: "/assets/testimonialAvatars/jean marc lecomte_photo.jpg",
      },
    },
    {
      testimonialHeading: "",
      testimonialText: `When I started with Fluent Chatter,
       I didn't really have confidence in myself. Now I'm much more comfortable,
       I find my words more easily and I've learned new words and have more confidence in myself. 
      A great experience that I recommend`,
      avatar: {
        name: "Sydney B.",
        title: "Student",
        imgsrc: "/assets/testimonialAvatars/Sydney B.jpg",
      },
    },
  ],
};

export const whyus_data = {
  heading: "Neden Fluent Chatter?",
  features: [
    {
      title: "Evinizin Konforunda Eğitim Alın",
      description:
        "Etkileşimli sınıflarımızda özgüveninizi artırın, grup koçlarınızla gündelik konuşmalarda gerçek dünya dilini uygulayın.",
      imageSrc: "/assets/whyus1.webp",
    },
    {
      title: "Tematik Modüller",
      description:
        "26 tematik modül, haftada bir modül. Her modülde, hafta boyunca inceleyip pratik yapabileceğiniz video dersler, etkileşimli alıştırmalar ve PDF dosyaları bulacaksınız.",
      imageSrc: "/assets/whyus2.webp",
    },
    {
      title: "Pratik Yap : Gerçek İnsanlarla Konuş",
      description:
        "Dil öğrenmenin kalbi PRATİK ! Platform dışında Telegram Gruplarında ve Zoom üzerinde yapılan canlı Speaking Groups seansları ile diğer platformlara göre çok daha fazla pratik yap!",
      imageSrc: "/assets/whyus3.webp",
    },
  ],
};

export const pricing_data = {
  heading: "Kurs Ucretlerimiz & Paketler",
  plans: [
    {
      type: "Basic",
      price: "15.000 TL",
      features: [
        "5 class credits",
        "No extra Speaking Groups",
        "Group classes: 1hr /1 credit",
        "Duo classes: 45 min/1 credit",
        "Private classes: 30 min /1 credit",
      ],
      color: "pink.500",
    },
    {
      type: "Super",
      price: "16.500 TL",
      features: [
        "10 class credits",
        "5 extra Speaking Groups",
        "Group classes: 1hr /1 credit",
        "Duo classes: 45 min/1 credit",
        "Private classes: 30 min /1 credit",
      ],
      color: "teal.500",
    },
    {
      type: "Premium",
      price: "18.000 TL",
      features: [
        "15 class credits",
        "10 extra Speaking Groups",
        "Group classes: 1hr /1 credit",
        "Duo classes: 45 min/1 credit",
        "Private classes: 30 min /1 credit",
      ],
      color: "orange.500",
    },
  ],
  describeHeading: "Paket Sistemi & Krediler",
  describeItems: [
    `Kurs Paketleri , kurs seviyesi  farketmeksizin tüm kurslar icin (Beginner/Intermediete - Upper Intermediate) geçerlidir.`,
    `Paketlerde sunulan krediler: Grup Dersleri, İkili Dersler ya da Özel dersler icin pakette belirtilen sürelerle kullanabilirsiniz.`,
    `Grup, İkili Dersler ya da Özel dersler, pakete dahil olan krediler ile rezerve edilebilir. Krediden fazla ders almak icin ek kredi satın alabilirsiniz.`,
  ],
};

export const mission_vision_data = {
  vision_heading: "Vizyonumuz",
  vision_text:
    "Amacımız kullanıcıların dil öğreniminde tam potansiyellerine ulaşmalarını sağlamaktır. Dil ögrenmenin beraberinde getirdigi kültürel zenginlik ve kültürler arasi alis-verisi de programimiza dahil ediyoruz. Dili ögrenirken ayni zamanda kendini ifade etme becerisinin gelismesi, dinledigini ya da okudugunu anlama ve etkin iletisim kurma becerileri gibi kisisel gelisimi de hedefleyen kapsamli bir platformu mekan ve zaman siniri olmadan kullanicilara ulastirmayi hedefliyoruz.",
  mission_heading: "Misyonumuz",
  mission_text:
    "Öğrencilere yenilikçi araçlar, uzman rehberliği ve  birbirine destek olan bir topluluk aracılığıyla Ingilizce iletişim kurma becerileri ve özgüveni kazandıran ilgi çekici, erişilebilir ve kişiselleştirilmiş bir öğrenme deneyimi sunmak. Ülkenin neresinde yaşadığınızdan bağımsız olarak kaliteli bir dil öğrenme platformunda kişisel gereksinimlerinize cevap veren, günümüz teknolojisinin sunduğu tüm olanaklardan yararlanarak benzersiz bir dil öğrenme deneyimi sunmak için çalışıyoruz. Dayanışma ve birlikte başarma duygusuyla birbirini motive eden ve destek olan gruplar ile bu deneyimi daha da özel hale getirmeyi amaçlıyoruz.",
};
